import React, { useState, useRef, } from 'react'
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import './Login.css';
import '../ModalBox.css';
import axios from 'axios';
import { BASE_URL } from '../../../appConstant';
import Loader from '../../loader/Loader';
import { toast } from 'react-toastify';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 500,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: 5,
    boxShadow: 24,
    p: 0,
};

function Login(props) {
    const { openLogin, setOpenLogin, setUser, setToken, setOpen } = props;
    const handleClose = () => setOpenLogin(false);

    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const inputRef = useRef([]);
    const [otpReq, setOtpReq] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFromErrors]= useState({})
    const [email, setEmail]= useState("")

    const handleOtp = (index, value) => {
        // setOtp(e.target.value);
        if (isNaN(value)) {
            return;
        }
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value !== '' && index < 5) {
            inputRef.current[index + 1].focus();
        }
        // setFromErrors((prevState) => ({
        //     ...prevState,
        //     otpErr: false,
        // }));
    }

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
            inputRef.current[index - 1].focus();
        }
    };
    const handleVerify = () => {
        setIsLoading(true);
        const newOtp = otp.join('');
        axios.post(`${BASE_URL}benchmark/verify`, {
            email: email,
            otp: newOtp
        }).then((res) => {
            const { token, name, email } = res.data.data;
            setUser((prevState) => ({ ...prevState, name, email }))
            localStorage.setItem("token", token);
            setToken(token)
            setOpenLogin(false);
            toast("Successfull login");
        }).catch((err) => {
            console.log(err, err?.response?.data?.error);
            setFromErrors((prevState) => ({
                ...prevState,
                otpErr: true,
                otpErrMessage: err?.response?.data?.error|| "Wrong OTP!"
            }));
        }).finally(() => {
            setIsLoading(false);
        })
    }


    const handleEmailChange=({target: {value}})=>{
        setEmail(value)
    }

    const handleRequestOTP = async (e) => {
        e.preventDefault();
        if (email === "") {
            setFromErrors((prevState) => ({
                ...prevState,
                email: true,
            }));
            return;
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setFromErrors((prevState) => ({
                ...prevState,
                invalidEmail: true,
            }));
            return;
        } 
        setIsLoading(true);
        axios.post(`${BASE_URL}benchmark/login`, {
            name: "no-need",
            email: email
        }).then((res) => {
            console.log(res.status === 'success');
            setOtpReq(true);
        }).catch((err) => {
            setFromErrors((prevState) => ({
                ...prevState,
                otpReqErr: err?.response?.data?.error|| "Too Many Atempts!"
            }));
        }).finally(() => {
            setIsLoading(false);
        })
    };


    const handleSignup=()=>{
        setOpenLogin(false);
        setOpen(true);
    }

    return (
        <div>
            <Modal
                open={openLogin}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    {
                        !otpReq ?
                            <div className='logContainer'>
                                <div>
                                    <h2 className='titleText'>Log In</h2>
                                </div>
                                <div className='inputBoxLog'>
                                    <label>Login via Email</label>
                                    <input type='text' className='inputStyleLog' value={email} onChange={handleEmailChange} placeholder='Enter Email Address' />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                    <button className='loginButton' onClick={handleRequestOTP}>Proceed</button>
                                </div>
                                <div className='loginLinkContainer'>
                                        <div>New User?</div>
                                        <button className='loginLink' onClick={() => handleSignup()}><u>Sign Up</u></button>
                                </div>
                                {formErrors.otpReqErr && (
                                    <p className="errorContainer">{formErrors.otpReqErr}</p>
                                )}
                            </div>
                            :
                            <div className='otpContainer'>
                                <div className='rightText'>
                                    <h2>OTP Verification</h2>
                                </div>
                                <div className='inputBox'>
                                    <label className="">We've sent an OTP on your registered Email Address</label>
                                    <div className='inputOTP'>
                                        {
                                            otp.map((value, index) => (
                                                <input
                                                    className='inputOtpStyle'
                                                    key={index}
                                                    type="text"
                                                    maxLength={1}
                                                    value={value}
                                                    onChange={(e) => handleOtp(index, e.target.value)}
                                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                                    ref={(ref) => (inputRef.current[index] = ref)}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                                {formErrors.otpErr && (
                                    <p className="errorContainer">{formErrors.otpErrMessage}</p>
                                )}
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                    <button
                                        onClick={handleVerify}
                                        type="button"
                                        className="loginButton"
                                    >
                                        Verify
                                    </button>
                                </div>
                                
                            </div>
                    }
                    {isLoading && <div style={{ zIndex: 999, position: "absolute", left: "50%", top: "50%" }}><Loader /></div>}
                </Box>
            </Modal>
        </div>
    )
}

export default Login