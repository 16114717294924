import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { BASE_URL } from '../../appConstant';
import './ModalBox.css';
import { createTheme } from '@mui/material/styles';
import Loader from '../loader/Loader';
import { toast } from 'react-toastify';

const theme = createTheme({
    palette: {
        background: {
            paper: '#3B7C95', // Change the color here
        },
    },
});


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 500,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: 5,
    boxShadow: 24,
    p: 0,
};

export default function ModalBox(props) {
    const { user, setUser, open, setOpen, setOpenLogin, setToken } = props;
    const handleClose = () => setOpen(false);

    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const inputRef = useRef([]);
    const [formErrors, setFromErrors] = useState({
        email: false,
        name: false,
        invalidEmail: false,
        otpErr: false,
        otpErrMessage: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser((state) => ({
            ...state,
            [name]: value,
        }));
        if (name === "email") {
            setFromErrors(prevState => ({
                ...prevState,
                email: false,
                invalidEmail: false
            }));
        } else {
            setFromErrors(prevState => ({
                ...prevState,
                [name]: false,
            }));
        }

    };
    const [otpReq, setOtpReq] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // const { name, email, mobileNo } = user;
        if (user.email === "") {
            setFromErrors((prevState) => ({
                ...prevState,
                email: true,
            }));
            return;
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email)) {
            setFromErrors((prevState) => ({
                ...prevState,
                invalidEmail: true,
            }));
            return;
        } else if (user.name === "") {
            setFromErrors((prevState) => ({
                ...prevState,
                password: true,
            }));
            return;
        }

        setIsLoading(true);
        axios.post(`${BASE_URL}benchmark/login`, {
            name: user.name,
            email: user.email,
            mobile_no: user.mobileNo
        }).then((res) => {
            // console.log(res.status === 'success');
            setOtpReq(true);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        })
    };

    const handleOtp = (index, value) => {
        // setOtp(e.target.value);
        if (isNaN(value)) {
            return;
        }
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value !== '' && index < 5) {
            inputRef.current[index + 1].focus();
        }
        setFromErrors((prevState) => ({
            ...prevState,
            otpErr: false,
        }));
    }

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
            inputRef.current[index - 1].focus();
        }
    };
    const handleVerify = () => {
        setIsLoading(true);
        const newOtp = otp.join('');
        axios.post(`${BASE_URL}benchmark/verify`, {
            email: user.email,
            otp: newOtp
        }).then((res) => {
            const { token, name, email } = res.data.data;
            setUser((prevState) => ({ ...prevState, name, email }))
            localStorage.setItem("token", token);
            setOpen(false);
            setToken(token)
            toast("Successfull Signup");
        }).catch((err) => {
            console.log(err, err?.response?.data?.error);
            setFromErrors((prevState) => ({
                ...prevState,
                otpErr: true,
                otpErrMessage: err?.response?.data?.error|| "Wrong OTP!"
            }));

        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleLogIn = () => {
        setOpen(false);
        setOpenLogin(true);
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {
                        !otpReq ?
                            <div className="loginContainer">
                                <div className='left'>
                                    <h3>Sign Up to access .....</h3>

                                </div>
                                <form className="right">
                                    <div className='rightText'>
                                        <h2>Sign Up</h2>
                                    </div>
                                    <div className="inputBox">
                                        <label className="">Full Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="inputStyle"
                                            id="name"
                                            placeholder="Enter Name"
                                            onChange={handleInputChange}
                                            value={user.name}
                                            disabled={otpReq}
                                        ></input>
                                    </div>
                                    {formErrors.name && (
                                        <p className="errorContainer">Name is required!</p>
                                    )}
                                    <div className="inputBox">
                                        <label className="">Email ID</label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="inputStyle"
                                            id="email"
                                            placeholder="Enter Email ID"
                                            onChange={handleInputChange}
                                            value={user.email}
                                            disabled={otpReq}
                                        ></input>
                                    </div>
                                    {formErrors.email && (
                                        <p className="errorContainer">Email is required!</p>
                                    )}
                                    {formErrors.invalidEmail && (
                                        <p className="errorContainer">Invalid Email!</p>
                                    )}
                                    <div className="inputBox">
                                        <label className="">Phone Number (optional)</label>
                                        <input
                                            type="number"
                                            name="mobileNo"
                                            className="inputStyle"
                                            id="mobileNo"
                                            placeholder="Enter Phone Number"
                                            onChange={handleInputChange}
                                            value={user.mobileNo}
                                            disabled={otpReq}
                                        ></input>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                        <button
                                            className='loginButton'
                                            onClick={handleSubmit}
                                            type="button"
                                        >
                                            Proceed
                                        </button>
                                    </div>
                                    <div className='loginLinkContainer'>
                                        <div>Existing User?</div>
                                        <button className='loginLink' onClick={() => handleLogIn()}><u>Log In</u></button>
                                    </div>
                                </form>
                            </div>
                            :
                            <div className='otpContainer'>
                                <div className='rightText'>
                                    <h2>OTP Verification</h2>
                                </div>
                                <div className='inputBox'>
                                    <label className="">We've sent an OTP on your registered Email Address</label>
                                    {/* <input className="inputStyle" type="text" value={otp} onChange={handleOtp} /> */}
                                    <div className='inputOTP'>
                                        {
                                            otp.map((value, index) => (
                                                <input
                                                    className='inputOtpStyle'
                                                    key={index}
                                                    type="text"
                                                    maxLength={1}
                                                    value={value}
                                                    onChange={(e) => handleOtp(index, e.target.value)}
                                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                                    ref={(ref) => (inputRef.current[index] = ref)}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                                {formErrors.otpErr && (
                                    <p className="errorContainer">{formErrors.otpErrMessage}</p>
                                )}
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                    <button
                                        onClick={handleVerify}
                                        type="button"
                                        className="loginButton"
                                    >
                                        Verify
                                    </button>
                                </div>
                            </div>
                    }
                    {isLoading && <div style={{ zIndex: 999, position: "absolute", left: "50%", top: "50%" }}><Loader /></div>}
                </Box>
            </Modal>
        </div>
    );
}