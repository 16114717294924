import './App.css';
import BenchmarkContainer from './components/benchmark/BenchmarkContainer';
import Header from './components/header/Header';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Login from './components/modal/loginExisting/Login';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    mobileNo: ""
  })

  const [openLogin, setOpenLogin] = useState(false);
  const [token,setToken] = useState(localStorage.getItem('token')) ;
  return (
    <div className="App">
      <ToastContainer />
      <Header
        openLogin={openLogin}
        setOpenLogin={setOpenLogin}
        setToken={setToken}
        token= {token}
      />
      <BenchmarkContainer
        user={user}
        setUser={setUser}
        open={open}
        setOpen={setOpen}
        setOpenLogin={setOpenLogin}
        setToken={setToken}
      />
      {
        openLogin &&
        <Login
          openLogin={openLogin}
          setOpenLogin={setOpenLogin}
          user={user}
          setUser={setUser}
          setToken={setToken}
          setOpen={setOpen}
          
        />
      }
    </div>
  );
}

export default App;
