import React, { useState } from 'react';
import '../modal/ModalBox.css';
import Login from '../modal/loginExisting/Login';
import { toast } from 'react-toastify';


function Header(props) {
    const { openLogin, setOpenLogin, token, setToken } = props;
    const handleLogin = () => {
        setOpenLogin(true);
    }

    const handleLogOut = () => {
        setToken(undefined);
        localStorage.removeItem('token');
        toast("Successfully Logged out")
    }
    return (
        <>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
                <h1 style={{ display: 'flex', justifyContent: 'center', margin: '0px', width: '90%', color: '#167C9F' }}>AMS ACOS Trends</h1>
                <div style={{ width: '10%', marginTop: '5px' }}>
                    {
                        token ? <button className='loginButton' onClick={handleLogOut}>Log out</button> : <button className='loginButton' onClick={handleLogin}>Log In</button>
                    }
                </div>
            </div>
        </>
    )
}

export default Header;